import React, { useState, useEffect } from "react";
import { navigate } from "gatsby"
import styled from "styled-components";
import Close from "../../../assets/img/zavrit.svg";

const ModalInner = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  z-index: 12;

  .modalWrap {
    background: #fff;
    width: calc(100% - 120px);
    max-width: 900px;
    margin: 0 auto;
    margin-top: 50px;
    border-radius: 5px;
    padding: 40px;
    position: relative;
    max-height: calc(100% - 80px - 50px - 50px);

    @media (max-height: 740px) {
      margin-top: 20px;
      max-height: calc(100% - 80px - 20px - 20px);
    }

    @media (max-width: 899px) {
      width: calc(100% - 70px);
      padding: 25px;
      margin-top: 10px;
      max-height: calc(100% - 70px);
    }

    @media (max-width: 400px) {
      width: calc(100% - 50px);
      padding: 15px;
      margin-top: 10px;
    }

    @media (max-width: 400px) {
      max-height: calc(100% - 50px);
    }


    .modalInner {
      max-height: calc(100vh - 80px - 50px - 50px);
      overflow: scroll;
      overflow-x: hidden;
      position: relative;

      @media (max-height: 740px) {
        max-height: calc(100vh - 80px - 20px - 20px);
      }

      @media (max-width: 899px) {
        max-height: calc(100vh - 70px);
      }

      @media (max-width: 400px) {
        max-height: calc(100vh - 50px);
      }
    }

    .close {
      background: none;
      border: 0;
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
      padding: 10px;
      z-index: 15;

      img {
        display: block;
        width: 20px;
        height: 20px;
      }
    }
  }

  h3 {
    font-family: Visuelt-Medium;
    font-size: 22px;
    color: #000000;
    margin-bottom: 15px;
    width: calc(100% - 40px);
  }

  p {
    font-family: Visuelt-Regular;
    font-size: 16px;
    color: #585858;
    line-height: 28px;
  }
  
  .buttons {
    margin-top: 30px;

    button {
      width: 250px;
      margin-right: 30px;
      height: 60px;
      font-family: Visuelt-Medium;
      font-size: 18px;
      text-align: center;
      border-radius: 3px;
      border: 0;
      margin-bottom: 20px;
      background: #ededed;
      color: #000000;

      &:hover {
        background: #E5EBF3;
        cursor: pointer;
      }
    }

    .positive {
      background: #243A58;
      color: #FFFFFF;

      &:hover {
        cursor: pointer;
        background: #1a2b42;
      }
    }
  }
`;


const Modal = ({setOpen, isOpen, children}) => {


  useEffect(() => {
	}, []);

  if (isOpen) {
    return (
      <ModalInner>
        <div className="modalWrap">
          <div className="modalInner">

          
            <button onClick={() => setOpen(false)} className="close">
              <img src={Close} alt="" />
            </button>
    
            {children}
          </div>
        </div>
      </ModalInner>
    )
  }

  else {
    return (null);
  }
  
}

export default Modal;